// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-etc-tsx": () => import("./../../../src/pages/etc.tsx" /* webpackChunkName: "component---src-pages-etc-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-level-tsx": () => import("./../../../src/pages/level.tsx" /* webpackChunkName: "component---src-pages-level-tsx" */),
  "component---src-pages-org-tsx": () => import("./../../../src/pages/org.tsx" /* webpackChunkName: "component---src-pages-org-tsx" */),
  "component---src-pages-subject-tsx": () => import("./../../../src/pages/subject.tsx" /* webpackChunkName: "component---src-pages-subject-tsx" */),
  "component---src-pages-year-tsx": () => import("./../../../src/pages/year.tsx" /* webpackChunkName: "component---src-pages-year-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-etc-tsx": () => import("./../../../src/templates/etc.tsx" /* webpackChunkName: "component---src-templates-etc-tsx" */),
  "component---src-templates-level-tsx": () => import("./../../../src/templates/level.tsx" /* webpackChunkName: "component---src-templates-level-tsx" */),
  "component---src-templates-organization-tsx": () => import("./../../../src/templates/organization.tsx" /* webpackChunkName: "component---src-templates-organization-tsx" */),
  "component---src-templates-subject-tsx": () => import("./../../../src/templates/subject.tsx" /* webpackChunkName: "component---src-templates-subject-tsx" */),
  "component---src-templates-year-tsx": () => import("./../../../src/templates/year.tsx" /* webpackChunkName: "component---src-templates-year-tsx" */)
}

